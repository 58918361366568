<script>
export default {
  name: 'EditForm',
  data() {
    return {
      progressing: false,
      initializing: true,
      permissions: [],
      model: {
        amount: null,
        created: null,
        description: null,
        id: null,
        payDate: null,
        paymentConfigId: null,
        receiptNumber: null,
        user: {
          email: null,
          firstName: null,
          fullName: null,
          id: null,
          lastName: null,
          mobile: null
        },
        userId: null,
        verified: true,
        verifiedDate: null
      }
    }
  }
}
</script>
